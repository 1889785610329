$.fn.serializeObject = function()
{
	var o = {};
	var a = this.serializeArray();
	$.each(a, function() {
		if (o[this.name] !== undefined) {
			if (!o[this.name].push) {
				o[this.name] = [o[this.name]];
			}
			o[this.name].push(this.value || '');
		} else {
			o[this.name] = this.value || '';
		}
	});
	return o;
};

$.extend({
    getUTMVar: function(name) {
        var results = new RegExp('[\?&]' + name + '=([^&#]*)')
                      .exec(getCookie('landing_page'));

        if (results === null) {
            return '';
        }

        return results[1] || '';
    },

    getLogSource: function() {
        var source = '';

        switch (API_KEY) {
            case 's9i4s3o79lsg93w5adys':
                source = 'HIC-iSelect';
            break;
            case 'm6i4s4n80leg83w8dast':
                source = 'HIC-HD';
            break;
            case 'o8f5s6w71lmd01kawlup':
                source = 'HIC-ItsMy';
            break;
            default:
                source = 'HIC-CW';
        }

        return source;
    },

    getUuid: function(){
        var uuid = getCookie('CHOOSEWELL_UUID');

        if (uuid === null) {
            return '';
        }

        return uuid;
    }
});
var ua = window.navigator.userAgent;
var isIE = /MSIE|Trident/.test(ua);
var mobilereg = /^0(4){1}[0-9]{8}$/;

var modalFields = {}; // for focus

// API url
var apiUrl = API_ENDPOINT+'/';

(function($) {

	if(isIE){
		$('.whole-page-wrap').prepend("<div class='whole-page-blur'></div>");

	}

	// add max length on email
    $('input[type="email"]').each(function(){
        $(this).on('keyup blur',function(){
            var currentVal = $(this).val()
            if(currentVal.length > 254)
                $(this).val(currentVal.slice(0,254))
        })
    });

	// tooltip
	$(window).resize(function() {
		$('.tooltip').remove();
	});

	if($('[data-toggle="tooltip"]').length > 0)
		$('[data-toggle="tooltip"]').tooltip();

	 // disable submit
	 $('#findDeals').submit(function() {
		if (enablesubmit === false) {
			return false;
		}
	});
	$('.hastooltip').mouseover(function(event) {
		if ($(window).width() < 769) {
			$('.hastooltip').next('.tooltip').hide();
			$(this).tooltip({
				trigger: 'click'
			});

			$('.checkbox label').click(function(event) {
				$('.tooltip').hide();
			});
		} else {
			$(this).tooltip('show');
		}
	});


	// submit steps
	$('body').on('click', '.form-submit', function(e) {
		e.preventDefault();
		var stepopen = $(this).attr('data-value');
		var steptovalidate = '#' + $(this).parents('.formstep').attr('id');
		
		if ($(this).hasClass('disable-submit')) {
			return false;
		}

		if (validateMyAjaxInputs(stepopen, steptovalidate)) {
			$(this).addClass('disable-submit').attr('disabled', true);

			if (stepopen === 'submitAjax') {

				var phoneval = $('#phone').val();
				if(smsSwapper === 2) {

					if(mobilereg.test(phoneval)) {

						submitSMScode(smsCode, phoneval, false, '#phone');

					} else {

						submitContent();
					}

				} else {

					submitContent();
				}

			} else if (stepopen === 'submit') {

				if($(this).hasClass('comment-form-submit')) {
					$(steptovalidate).find('input[type=submit]').click();
				}
				$(steptovalidate).submit();

			} else if (stepopen === 'next_step') {

				$('html, body').animate({
					'scrollTop': 0
				}, 300, 'swing');

				setTimeout(function () {
		
					$('#next-step-modal input').each(function (e) {
						modalFields[$(this).attr('id')] = $(this).offset().top;
					});
		
				}, 1000);


				$('#lead-form, .commercial, .feefo-review, .intro, .hide-section, .feefo-dynamic').hide(); // add hide section
				$('.quotes-sections').fadeIn();
				if(isIE){
						
					$('body').find('.whole-page-blur').addClass('show');
					$('.next-step-modal-wrap').css("background-color","rgba(0, 0, 0, 0)");
				}
				setTimeout(function(){
					$('body').addClass('showing-dummy');
					
				},100)

			} else if ( stepopen === 'modal-submit' ) {

				$('.next-step-modal-wrap').fadeOut();

				$('.hidden_name,#customername').val($('#name').val());
				$('.hidden_phone').val($('#phone').val());
				$('.hidden_email').val($('#email').val());

				setTimeout(function(){
					submitContent();
				}, 100);
			}

		} else {

			return false
		}

	});


	// modal
	$('body').on('click', '.close-next-step-modal', function(e){
		e.preventDefault();

		$('#lead-form, .commercial, .feefo-review, .intro, .hide-section, .feefo-dynamic').show(); // show section
		$('.quotes-sections').fadeOut();
		$('body').removeClass('showing-dummy');
		
		$('.multi-step-fields .form-submit').removeClass('disable-submit').removeAttr('disabled')
		if(isIE){
			$("body").find(".whole-page-blur").removeClass('show');
			$(".next-step-modal-wrap").attr("style","");
		}
		
	});

	
	// slider
	if($('.testimonial-slider').length > 0)
		$('.testimonial-slider').slick({
			dots: false,
			infinite: true,
			autoplay: true,
			autoplaySpeed: 4000,
			speed: 1000,
			slidesToShow: 1,
			slidesToScroll: 1
		});

	if($('.reasons-slide').length > 0)
		$('.reasons-slide').slick({
			dots: false,
			infinite: true,
			autoplay: true,
			autoplaySpeed: 4000,
			speed: 1000,
			slidesToShow: 1,
			slidesToScroll: 1
		});

	if($('.policy-slider').length > 0)
		$('.policy-slider').slick({
			dots: false,
			infinite: true,
			autoplay: true,
			autoplaySpeed: 4000,
			speed: 1000,
			slidesToShow: 1,
			slidesToScroll: 1,
			adaptiveHeight: true
		});

	if($('.lender-slider').length > 0)
		$('.lender-slider').slick({
			dots: false,
			infinite: true,
			autoplay: true,
			autoplaySpeed: 4000,
			speed: 1000,
			slidesToShow: 1,
			slidesToScroll: 1
		});


	// checkbox
	$('.checkbox input').each(function(i, value) {
		if($(this).is(':checked')) {
			$(this).parents('.checkbox').addClass('active');
		}
	});

	$('.checkbox input').change(function(e){
		$(this).parents('.checkbox').removeClass('active');
		if($(this).is(':checked')) {
			$(this).parents('.checkbox').addClass('active');
		}
	});

	// testimonial toggle
	$('.testimonial-content').each(function(i){
		$(this).find('p').each(function(i){
			if(i !== 1 && i !== 0) {
				$(this).hide();
			}

		})
	});

	$('.collapse-testimonial').click(function(e){
		e.preventDefault();
		if($(this).hasClass('active')) {
			$(this).removeClass('active');
			$(this).parents('.testimonial-item').find('.testimonial-content p').each(function(i){
				if(i !== 1 && i !== 0) {
					$(this).slideUp();
				}

			})
		} else {
			$(this).addClass('active');
			$(this).parents('.testimonial-item').find('.testimonial-content p').each(function(i){
				if(i !== 1 && i !== 0) {
					$(this).slideDown();
				}

			})
		}
	});

	$('.slick-arrow').click(function(){
		$('.collapse-testimonial').removeClass('active')
		$('.testimonial-content').each(function(i){
			$(this).find('p').each(function(i){
				if(i !== 1 && i !== 0) {
					$(this).hide();
				}

			})
		});
	});

	// check the cover
	var check_options = $('#checked_options').length > 0 ? $('#checked_options').val().split(', ') : '';

	if(check_options.length > 1) {
		$(this).removeAttr('checked');
		for(var x=0;x<check_options.length;x++) {
			$('#' + check_options[x]).attr('checked','checked');
		}
	}

	// scroll top by default
	$('a.btn').click(function(e){
		if($(this).attr('href') === '#')
			$('html, body').animate({
				'scrollTop': 0
			}, 300, 'swing');
	});


	var windowWidth = $(window).width();
	  function stepsMove() {
	    var stepActive = $('#steps .active').length;
	    var stepProgress = 0;
	    var stepPosition;
	    if (stepActive > 0) {
	      stepPosition = $('#steps .active').eq(stepActive - 1).offset();

	      if (stepActive > 3) {
	        stepProgress = 100 + "%";
	      } else {
	        if (windowWidth < 768) {
	          stepProgress = stepPosition.left + 36 + "px";
	        } else {
	          stepProgress = stepPosition.left + 70 + "px";
	        }
	      }
	      $('.stepprogress').css({'width': stepProgress});
	    }
	  }
	  stepsMove();

	  $(window).resize(function () {
	    var footerHeight = $('footer.lp-page').outerHeight();
	    $('#intro').css({'bottom': footerHeight});
	    stepsMove();
	    removeTool();
	  });

	  function removeTool() {
	  	$('.tooltip').remove();
	  }

	 // homepage hero slider
	 if($('.hero-slider').length > 0)
		 $('.hero-slider').slick({
			 dots: true,
			 arrows: false,
			 infinite: true,
			 autoplay: true,
			 autoplaySpeed: 4000,
			 speed: 1000,
			 slidesToShow: 1,
			 slidesToScroll: 1,
			 fade: true
		 });

	// trigger translate section via cookie
	if(checkCookie('translation_enabled') && $('.bar-btn').length == 0) {

		var _barbtn = '<div class="bar-btn"><a href="/form/step2-choosewell"'+location.search+' class="tran-active en-link">EN</a><a href="/form/step2-cw-chn"'+location.search+' class="class="chn-link"">中文</a></div>';

		$( _barbtn ).insertAfter('.logo');
	}

})(jQuery);



// ajax save request
var ajaxSaveLeadsRequest = function (aName, aEmail, aPhone, aState, aStage, aType, aHospitalList, aExtrasList, aFund, aAnalytics){
	var strQuery = 'state='+aState+'&lifestage='+aStage+'&covertype='+aType+'&hospitalcoverlist='+aHospitalList;
	strQuery += '&extrascoverlist='+aExtrasList+'&customername='+aName+'&email='+aEmail+'&phone='+aPhone+'&currentfund='+aFund+'&analytics='+aAnalytics;
	$.ajax({
		url: "/ajax-save-leads/",
		data:strQuery,
		type:'POST',
		data:'text'
	}).success(function(data) {
	}).error(function() {
	}).complete(function() {
	});
}

var resendSmsCode = function (phoneval) {
  //HICSearchData.phone = phoneval;
  submitSMScode(smsCode, phoneval, true, '#phone');
}
// redirect function. differs every on each page
var redirectThankyoupage = function () {
	submitContent();
}

var createForm = function() {
    return $("<form/>", {method: 'POST'});
}

var createInputAndAppend = function(form, name, value) {
    form.append($("<input>", {type: 'hidden', name: name, value: value}));
}

//  add hidden fields
var addHidden = function (key, value) {
	// Create a hidden input element, and append it to the form:
	var input = document.createElement('input');
	input.type = 'hidden';
	input.name = key;
	input.value = value;
	$("form#next-step").append(input);
}

// get utm by name
var getUTMByName = function (e) {
	e = e.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
	var t = "[\\?&]" + e + "=([^&#]*)";
	var n = new RegExp(t);
	var r = n.exec(window.location.search);
	if (r == null) return "";
	else return decodeURIComponent(r[1].replace(/\+/g, " "))
}

var submitContent = function() {

	if($('#loader').length > 0) {
		$('#loader').addClass('visible');
	}

	if ($('input[data-format="address"]').length > 0) {

		$('input[data-format="address"]').each(function(i, value) {

			prefill_hidden_address($(this));

		});
	}

	var params_object = $('#lead-form').serializeObject();

	var $form = createForm();
	var hospitalCovers  = [];
	var hospitalCoversLabels = [];
	var extraCoversLabels = [];
	var extraCovers     = [];
	var state = params_object.state;
	var postcode = params_object.postcode;
	var lifestage = params_object.lifestages;
	var age = params_object.age;

	$("input.hosp").each(function(){

		if ($(this).is(':checked')) {
			var inputLabel = $(this).attr('id');
			hospitalCovers.push($(this).val());
			hospitalCoversLabels.push($('label[for='+inputLabel+']').text());
		}
	});

	$("input.extra").each(function(){

		if ($(this).is(':checked')) {
			var inputLabel = $(this).attr('id');
			extraCovers.push($(this).val())
			extraCoversLabels.push($('label[for='+inputLabel+']').text());
		}
	});

	var hospitals   = [];
	var extras      = [];
	var uuid        = $.getUuid();
	var nameArr     = $.trim($('#name').val()).split(' ');
	var first_name 	= '';
	var last_name = nameArr.length > 1 ? nameArr[nameArr.length-1] : '[not provided]';

	for (var i = 0; i < nameArr.length - 1; i++) {
		if (i > 0) {
			first_name += ' ';
		}
		first_name += nameArr[i];
	}

	$('#lead-form .styled.hosp:checked').each(function(){
		hospitals.push($(this).val());
	});

	$('#lead-form .styled.extra:checked').each(function(){
		extras.push($(this).val());
	});

	var params      = 'first_name='+first_name;
	var log_params = '';

	if (nameArr.length > 1) {
		params += '&last_name='+last_name;
	} else {
		params += '&last_name=[not provided]';
	}

    createInputAndAppend($form, 'first_name', first_name);
    createInputAndAppend($form, 'last_name', last_name);
    createInputAndAppend($form, 'age', age);
    createInputAndAppend($form, 'email', $('#email').val());
    createInputAndAppend($form, 'phone', $('#phone').val());
	
	
	if(params.indexOf('ob_click_id') == -1 && $("input[name='ob_click_id']").length > 0) {
		params += '&ob_click_id='+$("input[name='ob_click_id']").val();
		createInputAndAppend($form, 'ob_click_id', $("input[name='ob_click_id']").val());
	}
	if(params.indexOf('click-id') == -1 && $("input[name='click-id']").length > 0) {
		params += '&click-id='+$("input[name='click-id']").val();
		createInputAndAppend($form, 'click-id', $("input[name='click-id']").val());
	}
	if(params.indexOf('vmcid') == -1 && $("input[name='vmcid']").length > 0) {
		params += '&vmcid='+$("input[name='vmcid']").val();
		createInputAndAppend($form, 'vmcid', $("input[name='vmcid']").val());
	}
	if(params.indexOf('fbclid') == -1 && $("input[name='fbclid']").length > 0) {
		params += '&fbclid='+$("input[name='fbclid']").val();
		createInputAndAppend($form, 'fbclid', $("input[name='fbclid']").val());
	}
	if(params.indexOf('gclid') == -1 && $("input[name='gclid']").length > 0) {
		params += '&gclid='+$("input[name='gclid']").val();
		createInputAndAppend($form, 'gclid', $("input[name='gclid']").val());
	}
	if(params.indexOf('conversion_name') == -1 && $("input[name='conversion_name']").length > 0) {
		params += '&conversion_name='+$("input[name='conversion_name']").val();
		createInputAndAppend($form, 'conversion_name', $("input[name='conversion_name']").val());
	}

	var fund = window['target_fund'] || '';
	var useTiersAlgoParam = $('#lead-form').find('input[name="optimizely"]').length > 0 ? '&use-tiers-algo=1' : '';
	var hacParam = $('#lead-form').find('input[name="optimizely_hac_trigger"]').length > 0 ? '&hearing-aids=true' : '';

    if (uuid.length) {
		$form.attr('action', '/quotes-results/?uuid=' + uuid + '&first_name='+ first_name + '&last_name='+ last_name  + '&state=' + state + '&lifestage=' + lifestage + '&hospital_cover_list=' + hospitals.join(',') + '&extra_cover_list=' + extras.join(',') + '&health_fund=' + $('#healthFund').val() + '&policy=' + $('#cmb-current-policy').val() + 'postcode=' + postcode + '&target_fund=' + fund + useTiersAlgoParam  + hacParam);
        $('body').append($form);
        $form.submit();
       //window.location = '/quotes-results/?uuid='+uuid+'&state='+state+'&lifestage='+$('#lifestage').val()+'&hospital_cover_list='+hospitals.join(',')+'&extra_cover_list='+extras.join(',')+'&first_name='+first_name+'&last_name='+last_name+'&email='+$('#email').val()+'&phone='+$('#phone').val()+'&health_fund='+$('#healthfund').val();
    } else {
    	var api     = new $.lead({
			endpoint        : apiUrl + 'lead/create',
			apikey          : API_KEY,
			callbackSuccess : function(data){
				console.log(data)
				if (data.error != 'null') {
					setCookie('current', 'yes', 0.0347222222);

					// set cookie  for conversion
					if(typeof data.data.isDuplicate == 'undefined')
						setCookie('trigger_element', 'true', 7);

					// when the submission is not coming from the formpage
					if($('body').hasClass('page-template-tpl-refer-friend'))
						return false;

					if (($('#next-step').attr('action') != '/' && $('#next-step').attr('action').indexOf('quotes-results') === -1) && $('#next-step').attr('action') !== '') {

						if(API_KEY == 's9i4s3o79lsg93w5adys') {
							window.location.href = $('#next-step').attr('action')  + '?uuid='+data.data.lead_uuid;
						} else {
							$('#next-step').submit();
						}

					} else {

						$form.attr('action', '/quotes-results/?uuid=' + data.data.lead_uuid + '&first_name='+ first_name + '&last_name='+ last_name  + '&state=' + state + '&lifestage=' + lifestage + '&hospital_cover_list=' + hospitals.join(',') + '&extra_cover_list=' + extras.join(',') + '&health_fund=' + $('#healthFund').val() + '&policy=' + $('#cmb-current-policy').val() + '&postcode=' + postcode + '&target_fund=' + fund + useTiersAlgoParam  + hacParam);

						$('body').append($form);

                    	$form.submit();
					}

				} else {
					console.log('data.error');
					// redirectDefaultuuid();
					window.location = '/error-page/';
				}
			},
			callbackFailure : function(data){
				console.log('callback error', data);
				redirectDefaultuuid();
				// window.location = '/error-page/';
			}
		});

		params += '&email='+$('#email').val();
		params += '&phone='+$('#phone').val();
		params += '&health_fund='+$('#healthFund').val();
		params += '&policy=' + $('#cmb-current-policy').val();
		params += '&life_stage='+ lifestage;
		params += '&state='+state;
		params += '&postcode='+postcode;
		params += '&ga_client_id='+clientId;
		

		var coverType = '';
        if (hospitals.length > 0 && extras.length > 0) {
            coverType = 'Combined';
        } else if (hospitals.length > 0) {
            coverType = 'Hospital';
        }  else if (extras.length > 0) {
            coverType = 'Extras';
        }
		if (API_KEY == 'm6i4s4n80leg83w8dast') {
	        if (hospitals.length > 0 && extras.length > 0) {
	            coverType = 'Combined';
	        } else if (hospitals.length > 0) {
	            coverType = 'Hospital';
	        }  else if (extras.length > 0) {
	            coverType = 'Extras';
	        }
	    } else if(API_KEY == 's9i4s3o79lsg93w5adys') {
	        if (hospitals.length > 0 && extras.length > 0) {
	        	coverType = 'Both';
	        } else if (hospitals.length > 0) {
	            coverType = 'Hospital Only';
	        }  else if (extras.length > 0) {
	            coverType = 'Ancillary Only';
	        }
	    }else if( API_KEY == 'UnSRzWcL8pz2IZ1h7OlQ'){
			coverType = params_object.coverType;
			params +='&edm_tracking_id='+params_object.edm_tracking_id;
			params +='&livesubmitid ='+params_object.livesubmitid;
		}

	    if(API_KEY == 'm6i4s4n80leg83w8dast' || API_KEY == 's9i4s3o79lsg93w5adys') {
	    	params += '&policy_type=Both';
		    params += '&promotions=';
		    params += '&message=';
		    params += '&motivator=';

	    }
        params += '&cover_type='+coverType;
        params += '&hospital_cover_list='+hospitals.join(',');
        params += '&extras_cover_list='+extras.join(',');

	    params += '&form_page='+encodeURIComponent($('#formpage').val());
	    params += '&landing_page='+encodeURIComponent(getCookie('landing_page'));
		
		// for parameter checking
		var param_check = parse_query_string(params);
		
		// append utm params
		let landing_cookie_val = checkCookie('landing_page') ? getCookie('landing_page') : '',
			landing_split = landing_cookie_val.split('?'),
			landing_json = landing_split.length > 1 ? parse_query_string(landing_split[1]) : {};

		for(let val in landing_json) {
			if(val.indexOf('utm') !== -1) {
				if (!param_check.hasOwnProperty(val)){
					params += '&' + val + '=' + landing_json[val];
				}
			}
		}

		if(params.indexOf('utm_source') == -1) {
			params += '&utm_source='+getCookie('Source');
		}

		if(params.indexOf('utm_medium') == -1) {
			params += '&utm_medium='+getCookie('Medium');
		}

		// glid
		if(params.indexOf('gclid') == -1) {
			if(checkCookie('gclid'))
				params += '&gclid=' + getCookie('gclid');
		}
		
		// add hidden fields
		var param_check2 = parse_query_string(params);
		$('input[name="landingpage"]').remove();
		$('#lead-form').find('input[type="hidden"]').each(function(){
			var hidden_name = $(this).attr('name');
			var hidden_value = $(this).val();
			if(hidden_name != "apikey"){ // skip if apikey is in parameter
				if (!param_check2.hasOwnProperty(hidden_name)){
					params += '&'+hidden_name+'='+ encodeURIComponent($("input[name="+hidden_name+"]").val());
				}
			}
		});

		var lc_params = params;
		
		params += '&apikey='+API_KEY;
		log_params = parse_query_string(params);
		
		// send to the API
		submitLogger(JSON.stringify(log_params), $.getLogSource());

		// send to lead/create
		api.create(lc_params);

	}
	
	function redirectDefaultuuid() {
		let default_uuid = '40d48d15-bb29-4989-b16f-4528f026dbd4';

		$form.attr('action', '/quotes-results/?uuid=' + default_uuid + '&state=' + state + '&lifestage=' + lifestage + '&hospital_cover_list=' + hospitals.join(',') + '&extra_cover_list=' + extras.join(',') + '&health_fund=' + $('#healthFund').val() + '&policy=' + $('#cmb-current-policy').val() + '&postcode=' + postcode + '&target_fund=' + fund + useTiersAlgoParam  + hacParam);

		// submit data to logger and use default uuid for quotes results redirect
		submitLogger(JSON.stringify(log_params), $.getLogSource(), 'lead error submission in lead/create');

		$('body').append($form);

		// proceed to redirect
		setTimeout(() => {
			$form.submit();
		}, 3000);
		
	}

}

function getState(qryStr) {
	var matchResults = qryStr.match(/state=(.*)&/);

	if(matchResults === null) {
		return 'NSW';
	}

	const segments = matchResults.pop().split('&');

	return segments[0].toUpperCase();
}

$(document).on('click','.js-videoPoster',function(e) {
  e.preventDefault();
  var poster = $(this);
  var wrapper = poster.closest('.js-videoWrapper');
  videoPlay(wrapper);
});

function getFundData(fundName, policyDictionary) {	
	if(!policyDictionary[fundName])
		return [];

	return policyDictionary[fundName] || [];
}

function getPolicies(fundName) {
	var ret = [
		{
			value: '',
			label: window.isChinese ? '请选择您当前的政策' : 'Please select your current policy',
		},
	];
	//window.policies defined in ppcsections/cover-section-edm.php
	if(window.policies) {
		var fundData = getFundData(fundName, window.policies);		
		if(fundData) {
			for(var i = 0; i < fundData.length; i++) {
				ret.push({
					value: fundData[i],
					label: fundData[i],
				});
			}
		}
	}

	return ret.concat(
		[
			{
				value: 'other',
				label: 'Other',
			},
			{
				value: 'not_sure',
				label: 'I Don\'t Know',
			}
		]
	);
}

function parsePoliciesToOptionsHtml(policyList) {
	var ret = '';

	for(var i = 0; i < policyList.length; i++) {
		ret += '<option value="' + policyList[i].value + '">' + policyList[i].label +'</option>';
	}

	return ret;
}

$(document).on('change', '#healthFund', function(e) {
	if ($('#cmb-current-policy').length === 0) { // do this only if there's a current policy dropdown...
		return;
	}

	const noValueOptions = [
		'not insured',
		'not_sure',
		'other',
		'',
	];
	
	$('#cmb-current-policy').prop('disabled', false);
	// const state = getState(document.location.search);	

	var fundPolicies = getPolicies(e.target.value);
	
	if (e.target.value === 'not insured') {
		fundPolicies.unshift({
			value: 'not insured',
			label: 'Not Insured'
		});
	}

	$('#cmb-current-policy').empty();
	$('#cmb-current-policy').html(parsePoliciesToOptionsHtml(fundPolicies));

	$('#cmb-current-policy').val('');

	if (!window.policies[e.target.value] && noValueOptions.indexOf(e.target.value) < 0 ) {
		$('#cmb-current-policy').val('other');
	} else if (noValueOptions.indexOf(e.target.value) >= 0) {
		$('#cmb-current-policy').val(e.target.value);
		$('#cmb-current-policy').prop('disabled', true);
	}
});

function videoPlay(wrapper) {
  var iframe = wrapper.find('.js-videoIframe');

  var src = iframe.data('src');

  wrapper.addClass('videoWrapperActive');

  iframe.attr('src',src);
}


// feefo update
var feefo_request = function() {

	this.reviewCount = 0;
	this.settings = {
		success: function(data) {
			console.log('success', data)
		},
		fail: function(data){
			console.log('failed', data)
		},
		scanData: function(feedback) {
			console.log(feedback);
		}
	}

	var callBack = this.settings;

	this.submit = function() {
		$.ajax({
			type: "GET",
			url: templurl + '/inc/_feefo-request.php',
			success: function(data) {
				var data = JSON.parse(data);
				callBack.success(data);
			},
			error: function(e, b, c) {
				callBack.fail(e, b, c);
			}
		});
	}

	this.scanData = function (feedbackData) {

		this.reviewCount = 0;

		$.each(feedbackData, function (key, data) {

			var feedback = {
				id : data.FEEDBACKID,
				data : data.HREVIEWDATE,
				share : data.FACEBOOKSHARELINK,
				comment : data.CUSTOMERCOMMENT,
				description : data.DESCRIPTION,
				rating : data.HREVIEWRATING
			}

			callBack.scanData(feedback);

		});
	}
}

$(function(){

	// feefo
	var feefo_score = new feefo_request;

	feefo_score.settings.success = function(data) {

		// summary
		var feedbackData = data.FEEDBACKLIST.FEEDBACK;
		var summaryData = data.FEEDBACKLIST.SUMMARY;
		var summaryAverage = (parseFloat(summaryData.AVERAGE) > 99 ? 1 : parseFloat("0."+summaryData.AVERAGE));
		var fiveStarAverage = summaryData.FIVESTARAVERAGE.toString().indexOf('.') != -1 ? summaryData.FIVESTARAVERAGE : summaryData.FIVESTARAVERAGE.toString() + '.0';
		var summary_score = (summaryAverage === 1 ? 5 : 5 * summaryAverage);

		fiveStarAverage = parseFloat(fiveStarAverage) < 4 ? '4.0' : fiveStarAverage;

		// feefo dynamic score
		if( typeof(feefo_dynamic) !== 'undefined' && feefo_dynamic ) {
			if ($('.the-score').length > 0) {
				$('.star-after').css('width', summaryData.AVERAGE + "%");
				$('.the-score').text(fiveStarAverage);
			}
		}

		if($('.faq-left-rate').length > 0)
			$('.faq-left-rate span').text(fiveStarAverage)

		// reviews slider
		if($('.reviewSlideContainer').length > 0 ) {
			var slideItems = getFeefoFeedback(feedbackData);
			activateSlider(slideItems);
		}

		if($('.feefo-reviews').length > 0) {

			$('.stars-front').css('width',summaryData.AVERAGE + "%");
			$('.feefo-reviews .review').remove();

			// feedback list
			feefo_score.scanData(feedbackData);
		}

		// star rating
		if($('.star-rating').length) {
			$('.star-rating .star-top').css('width', summaryData.AVERAGE + "%");
		}
	}

	feefo_score.settings.scanData = function (feedback) {

		var review_count = feefo_score.reviewCount;

		if(feedback.comment.toLowerCase().indexOf("service rating") > -1) {
			feedback.comment = feedback.comment.split('<br />');

			if(feedback.comment.length > 1) {

				var feedbackComment1 = "<p>"+ feedback.comment[0].replace("Service rating :", "<strong>Service rating:</strong>") + "</p>";
				var feedbackComment2 = "<p>"+ feedback.comment[1].replace("Product :", "<strong>Product:</strong>") + "</p>";

				$('<div class="review"></div>')
				 .html(feedbackComment1 + feedbackComment2).appendTo('.feefo-reviews');

				 feefo_score.reviewCount++;
			}

			 if(review_count > 5) {
				 return false;
			 }
		}
	}

	feefo_score.submit();

	function getFeefoFeedback(feedbackData) {

		var slideItems = '';
		var slideCount = 0;

		$.each(feedbackData, function(key, data) {
			if (data.SERVICESTARRATING >= 4 && slideCount < 5) {
				slideItems += generateSlide(data);
				slideCount++;
			}

		});

		return slideItems;

	}

	function generateSlide(data) {
		var serviceRating = data.SERVICESTARRATING;
		var customercomment = data.SHORTCUSTOMERCOMMENT.toLowerCase().indexOf('service rating') !== -1 ? data.SHORTCUSTOMERCOMMENT.split('<br /')[0].replace(/service rating : /gi, '') : data.SHORTCUSTOMERCOMMENT;
		var reviewTitle = data.REVIEWTITLE;
		var customerData = typeof data.PUBLICCUSTOMER !== 'undefined' ? data.PUBLICCUSTOMER : '';
		var feefo_customer = customerData !== '' ? (typeof customerData.LOCATION !== 'undefined' ? '<strong>' + customerData.NAME + '</strong> in ' + customerData.LOCATION :  '<strong>' + customerData.NAME + '</strong>') : '<strong>Trusted Customer</strong>';
		var readMore = typeof data.READMOREURL !== 'undefined' ? data.READMOREURL : '#';

		if(customercomment.length > 200)
			customercomment = customercomment.slice(0,200) + '...';

		var slideItem_str = '<a class="feefo-review-item"><div class="feefo-review-rating"><div class="feefo-customer"></div><div class="feefo-star-rating"><div class="feef-star"></div><div class="feefo-star-empty"></div></div></div><div class="feefo-review-title"></div><div class="feefo-review-content"></div></a>';

		var slideItem_elem = $(slideItem_str);
		slideItem_elem.attr('href', readMore );
		slideItem_elem.find('.feefo-customer').html(feefo_customer);
		slideItem_elem.find('.feef-star').addClass('star-rate-' + serviceRating);
		slideItem_elem.find('.feefo-review-title').html(reviewTitle);
		slideItem_elem.find('.feefo-review-content').html(customercomment);
		return slideItem_elem[0].outerHTML;
	}

	function activateSlider(html) {

		$('.reviewSlideContainer').html(html);

		$('.reviewSlideContainer').slick({
			dots: false,
			infinite: true,
			speed: 1000,
			cssEase: 'linear',
			autoplay: true,
			autoplaySpeed: 3000,
			prevArrow: null,
			nextArrow: null
		});
	}


	$('body').on('focus', '#next-step-modal input', function(e) {

        if(typeof modalFields.name !== 'undefined') {

            var currentFieldtop = modalFields[$(this).attr('id')] - 40;
    
            setTimeout(function(){
                $('.next-step-modal-wrap').stop().animate({scrollTop: currentFieldtop}, 500, 'swing');
            }, 100);

        }
    
	});

	// $('.basic-details-content-wrap input').each(function (e) {
	// 	modalFields[$(this).attr('id')] = $(this).offset().top;
	// });
	
	// $('body').on('focus', '.basic-details-content-wrap input', function(e) {

    //     if(typeof modalFields.postcode !== 'undefined') {

    //         var currentFieldtop = modalFields[$(this).attr('id')] - 10;
    
    //         setTimeout(function(){
    //             $('body, html').stop().animate({scrollTop: currentFieldtop}, 500, 'swing');
    //         }, 100);

    //     }
    
    // });

    $('body').on('click', '.panel-trigger', function (e) {
        e.preventDefault();
        var headingId = '#' + $(this).parents('.panel-heading').attr('id');

        setTimeout(function() {

            var headingTop = $(headingId).offset().top;

            $('body, html').stop().animate({scrollTop: headingTop}, 500, 'swing');
            
        }, 500);
    });	

    var clickEventTooltip = "click";
    $('body').on(clickEventTooltip, function (evt) {
	    var checkAttr = $(evt.target).attr('data-toggle');
	    if(checkAttr == "tooltip"){
	      $(evt.target).tooltip("show");
	      return;
	    }
	    $('[data-toggle="tooltip"]').tooltip("hide");
  	});

	setTimeout(function(){
		$('.ppc-x-quotes .postcode-autocomplete').attr('type', 'tel');
	}, 500);

	// fill hidden field
	$('#agefund').change(function(){
		$('#hidden_age').val($(this).val());
  	});
	
});
